@import "../scss/color.scss";

body {
  font-family: "Montserrat", sans-serif !important;
}
.text-dark {
  color: var(--black) !important;
}
.text-white {
  color: var(--white) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.text--soft-green {
  color: var(--soft-green) !important;
}
.text-light-green {
  color: var(--light-green) !important;
}
.text-dark-green {
  color: var(--dark-green) !important;
}
.bg-dark {
  background-color: var(--black) !important;
}
.bg-white {
  background-color: var(--white) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
}
.bg--soft-green {
  background-color: var(--soft-green) !important;
}
.bg-light-green {
  background-color: var(--light-green) !important;
}
.bg-dark-green {
  background-color: var(--dark-green) !important;
}
.bg-orange {
  background-color: var(--orange) !important;
}
.bg-green {
  background-color: var(--green) !important;
}
.montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.inter {
  font-family: "Inter", sans-serif !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-style: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-38 {
  font-size: 38px !important;
}
.fs-42 {
  font-size: 42px !important;
}
.fs-46 {
  font-size: 46px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mt-50 {
  margin-top: 50px;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.gen-container {
  padding: 34px 80px;
}

.dropbtn {
  background-color: transparent;
  color: var(--blue);
  font-size: 16px;
  border: none;
}

.dropdown {
  z-index: 3523 !important;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  border-radius: 10px;
  position: absolute;
  background-color: var(--bg-gray-text);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a:first-child,
button:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}
.dropdown-content a:last-child,
button:last-child {
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a,
button {
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover,
button:hover {
  background-color: var(--gray1);
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: transparent;
}
.bagis-yap-btn {
  display: flex;
  width: 198px;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--dark-green);
  background-color: var(--dark-green);
  color: var(--soft-green) !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    background-color: var(--white) !important;
    border: 1px solid var(--dark-green) !important;
    color: var(--dark-green) !important;
    transition: 0.3s !important;
  }
}
.accordions-mobile-header {
  .accordion-button {
    border: none !important;
  }
}
.bagis-yap-btn {
  display: flex;
  padding: 20px 20px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--soft-green) !important;
  background-color: var(--soft-green) !important;
  color: var(--dark-green) !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    background-color: var(--dark-green) !important;
    border: 1px solid var(--dark-green) !important;
    color: var(--soft-green) !important;
    transition: 0.3s !important;
  }
}
.accordion {
  --bs-accordion-border-color: transparent;
  --bs-accordion-btn-padding-y: 0.6rem;
}
.accordion-button {
  padding: 15px 0px !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none !important;
  color: var(--dark-green);
}
.accordion:focus {
  box-shadow: none !important;
}
.outline-white-btn {
  display: flex;
  padding: 13px 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--white);
  color: var(--white);
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}
.white-btn {
  display: flex;
  padding: 13px 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--dark-green);
  font-family: Inter;
  font-size: 18px;
  border: 1px solid var(--white);
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--white);
}
.position-relative-slider {
  position: relative;
  z-index: 1 !important;
  .position-absolute-slider-text {
    z-index: 9093 !important;
    position: absolute;
    top: 50%;
    right: -200px;
    transform: translate(-50%, -50%);
    padding: 20px;
  }
}

.tabs {
  padding: 50px 80px;
  .nav-link:hover {
    background-color: var(--dark-green);
    color: var(--soft-green) !important;
    border-color: transparent !important;
    font-weight: 500;
    padding: 11px 33px;
    font-style: 15px;
    .btn-circle {
      background-color: var(--dark-green);
    }
  }
  .nav-link {
    border-radius: 10px;
    border: 1px solid var(--gray2);

    color: var(--gray-text);
    font-weight: 500;
    padding: 11px 33px !important;
    width: 100%;
    height: 100%;
    font-style: 15px;
    .btn-circle {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: var(--gray2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tab-content {
    margin-top: 30px;
    margin-left: 126px;
  }
}

.project {
  margin-bottom: 80px;
  .project-title {
    color: var(--dark-green);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 60px !important;
  }
  position: relative !important;
  .item {
    .card {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      border: none !important;
      height: 439px;
      img {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        height: 300px;
      }
      .title {
        color: var(--black);
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px !important;
      }
      .card-text {
        color: var(--black);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .project-absolute-btn {
    #left-button {
      position: absolute;
      bottom: 220px;
      z-index: 999;
      left: 0px;
    }
    #right-button {
      position: absolute;
      bottom: 220px;
      z-index: 999;
      right: 0px;
    }
  }
}

.not-alone {
  padding: 58px 0px;
  .light-green-btn {
    display: inline-flex;
    padding: 13px 45px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--soft-green);
    color: var(--dark-green);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    gap: 10px;
    border: 1px solid transparent !important;
    &:hover {
      border: 1px solid var(--light-green) !important;
      background-color: var(--dark-green) !important;
      color: var(--light-green) !important;
      transition: 0.2s ease-in !important;
    }
  }
  img {
    margin-top: 7px;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  .left-side {
    padding: 30px;
    margin-left: 103px;
    .section-image {
      position: absolute;
      z-index: 3333;
      top: -50px;
      right: 0;
    }
  }
  span {
    color: var(--white);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px !important;
  }
  .title {
    color: var(--light-green);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 18px !important;
    line-height: normal;
  }
  .desc {
    color: var(--white);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 42px !important;
  }
  .left-border-desc {
    color: var(--white);
    margin-bottom: 42px !important;
    padding-left: 10px;
    border-left: 6px solid var(--light-green);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    span {
      color: var(--light-green);
    }
  }
}
.aid-campaigns {
  margin-top: 40px;
  color: var(--dark-green);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 60px !important;
  .help-card {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    border-radius: 10px;
    border: 1px solid var(--gray2);
    background-color: var(--white);
    img {
      max-width: 100%;
      max-height: 100%;
      width: 400px;
      height: 250px;
      object-fit: cover;
    }
    .help-title {
      margin: 20px 0px;
      color: var(--black);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
    input {
      padding-right: 70px;
    }
    input:focus {
      box-shadow: none !important;
      border-color: var(--light-green);
    }
    .mini-green-btn {
      background-color: var(--light-green);
      color: var(--dark);
      font-family: Montserrat;
      font-size: 16px !important;
      font-weight: 400;
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;

      &:hover {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
      &:active {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
    }
    .bg-dark-green {
      margin-top: 15px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      padding: 13px 0px;
      border-radius: 10px;

      &:hover {
        background-color: var(--light-green) !important;
        color: var(--dark-green) !important;
      }
    }
  }
}
.mini-green-absolute {
  top: 0;
  right: 0;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.outline-dark-green {
  border-radius: 10px !important;
  border: 2px solid var(--dark-green) !important;
  &:hover {
    background-color: var(--soft-green) !important;
    color: var(--dark-green) !important;
  }
}
.support {
  img {
    max-width: 100%;
    width: 400px;
    max-height: 100%;
    height: 194px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-end-start-radius: 10px;
  }
  .sup-title {
    color: var(--dark-green);
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
  }
  .sup-card {
    .bg-dark-green {
      &:hover {
        background-color: var(--light-green) !important;
        color: var(--dark-green) !important;
        transition: 0.2s ease-in !important;
      }
    }
    border-radius: 10px;
    border: 1px solid var(--gray2);
    background-color: var(--white);
    .sup-title {
      color: var(--black);
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .bg-dark-green {
    max-width: 100%;
    padding: 12px;
    width: 300px;
  }
  .progress {
    background-color: var(--dark-green);
    .progress-bar {
      background-color: var(--light-green);
    }
  }
  input {
    padding: 12px;
    &:focus {
      box-shadow: none !important;
      border-color: var(--light-green);
    }
  }
  .boxes-content {
    gap: 55px;
  }
  .mini-green-btn {
    top: 1px;
    padding: 12px;
    right: 0px;
    width: 20%;
    background-color: var(--light-green);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-radius: 0.375rem;
    &:hover {
      background-color: var(--soft-green) !important;
      color: var(--dark);
    }
    &:active {
      background-color: var(--soft-green) !important;
      color: var(--dark);
    }
  }
}
.footer {
  ul {
    li {
      a {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  color: var(--white);
  padding: 52px 58px;
  .footer-list {
    gap: 4rem;
  }
  .lines {
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--light-green) !important;
  }
}
.login-register {
  padding: 25px 220px 80px;

  label {
    color: var(--black);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }
  .inputs {
    height: 50px !important;
    ::placeholder {
      color: var(--gray-text);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
    }
    &:focus {
      box-shadow: none !important;
      border-color: var(--dark-green);
    }
  }
  .gonder-btn {
    border-radius: 10px;
    border: 1px solid var(--dark-green);
    background: var(--dark-green);
    display: flex;
    max-width: 100%;
    max-height: 100%;
    width: 450px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--light-green);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      background-color: var(--light-green);
      color: var(--dark-green);
    }
  }
  .iletisim-form {
    padding: 0px 21px;
  }
}
.bg-dark-green-section {
  background-color: var(--dark-green);
  padding: 60px;
}
.kurumsal-container {
  padding: 80px;
}
.kurumsal-container {
  a {
    color: var(--black);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    width: 360px;
    height: 60px;
    padding: 21px 228px 19px 25px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--dark-green);
  }
  padding: 80px 100px;
  .active-tabs {
    border-radius: 5px;
    border: 1px solid var(--gray2);
    background: var(--dark-green);
    color: var(--light-green) !important;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
  .tabs {
    display: flex;
    width: 360px;
    height: 60px;
    padding: 20px 236px 20px 25px;
    align-items: center;
    &:hover {
      border-radius: 5px;
      border: 1px solid var(--gray2);
      background: var(--dark-green);
      color: var(--light-green) !important;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .basin-img {
    max-width: 100%;
    max-height: 100%;
    width: 600px !important;
    height: 560px !important;
    object-fit: cover;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  .gazete-haberi-img {
    max-width: 100%;
    max-height: 100%;
    width: 384px;
    object-fit: cover;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  .basin-video {
    max-height: 100%;
    max-width: 100%;
    width: 410px;
    height: 235px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    object-fit: cover;
  }
  .yonetim-card {
    .name {
      color: var(--black);
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
    }
    .title {
      color: var(--black);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
    }
    .list-item {
      color: var(--black);
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
    }

    .owner-img {
      width: 420px;
      height: 450px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
      object-fit: cover;
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
        rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }
  }
  .yonetim-card {
    border-bottom: 1px solid var(--gray1) !important;
    padding: 55px 0px;
  }
}
.bg-dark-green-btn {
  background-color: var(--dark-green) !important;
  color: var(--dark);
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 400px;
  border: 1px solid var(--dark-green);
  width: 75px;
  &:hover {
    background-color: transparent !important ;
    color: var(--dark-green) !important;
    border: 1px solid var(--dark-green) !important;
  }
}
.bagislar {
  padding: 80px !important;
  .help-card {
    max-width: 100%;
    max-height: 100%;
    width: 380px !important;
    border-radius: 10px;
    border: 1px solid var(--gray2);
    background-color: var(--white);
    img {
      max-width: 100%;
      max-height: 100%;
      width: 400px;
      height: 250px;
      object-fit: cover;
    }
    .help-title {
      margin: 20px 0px;
      color: var(--black);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
    input:focus {
      box-shadow: none !important;
      border-color: var(--light-green);
    }
    .mini-green-btn {
      background-color: var(--light-green);
      color: var(--dark);
      font-family: Montserrat;
      font-size: 16px !important;
      font-weight: 400px;
      &:hover {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
      &:active {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
    }
    .bg-dark-green {
      margin-top: 15px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      padding: 13px 0px;
      border-radius: 10px;

      &:hover {
        background-color: var(--light-green) !important;
        color: var(--dark-green) !important;
      }
    }
  }
}
.category-content {
  padding: 50px 80px !important;

  margin-top: 0px;
  color: var(--dark-green);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 60px !important;
  padding: 40px 200px;
  .help-card {
    max-width: 100%;
    max-height: 100%;
    width: 380px !important;
    border-radius: 10px;
    border: 1px solid var(--gray2);
    background-color: var(--white);
    img {
      max-width: 100%;
      max-height: 100%;
      width: 400px;
      height: 250px;
      object-fit: cover;
    }
    .help-title {
      margin: 20px 0px;
      color: var(--black);
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
    input:focus {
      box-shadow: none !important;
      border-color: var(--light-green);
    }
    .mini-green-btn {
      background-color: var(--light-green);
      color: var(--dark);
      font-family: Montserrat;
      font-size: 16px !important;
      font-weight: 400px;
      &:hover {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
      &:active {
        background-color: var(--soft-green) !important;
        color: var(--dark);
      }
    }
    .bg-dark-green {
      margin-top: 15px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      padding: 13px 0px;
      border-radius: 10px;

      &:hover {
        background-color: var(--light-green) !important;
        color: var(--dark-green) !important;
      }
    }
    .outline-dark-green {
      font-size: 16px;
      font-weight: 600;
      padding: 13px 0px;
      border-radius: 10px;
      border: 1.5px solid var(--dark-green);
      background-color: var(--white);
    }
  }

  .bagis-sepeti {
    display: flex;
    padding: 20px 59px 20px 68px;
    justify-content: flex-center;
    align-items: center;
  }
  .list-group-flush > .list-group-item:last-child {
  }
  .basket-all {
    width: 18rem;
    border-radius: 10px;
  }
  .category-content .help-card input:focus {
    box-shadow: none !important;
  }
}
.hesap-container {
  padding: 80px 70px;
  .bank-card {
    border-radius: 10px;
    border: 1px solid rgba(34, 34, 34, 0.3);
    display: flex;
    padding: 18px 20px 19px 20px;
    justify-content: center;
    align-items: center;
  }
}
.hesap-card {
  margin: 5px 60px;
  padding: 30px 50px;
  border-radius: 10px;
  margin-bottom: 100px !important;
  border: 1px solid rgba(128, 128, 128, 0.39);
}
.giris-yap-content {
  border-radius: 15px;
  border: 1px solid var(--gray2);
  flex-shrink: 0;
  width: 1000px;
  max-width: 100%;
  .image-box {
    border-top-left-radius: 14px;
    object-fit: cover;
    max-height: 100%;
    width: 100%;
    border-end-start-radius: 14px;
  }
  .nav-tabs {
    --bs-nav-tabs-border-width: none;
    --bs-nav-tabs-border-color: none;
    width: 100%;
    .nav-link {
      cursor: pointer;
      width: 100%;
      padding: 30px !important;
      background-color: var(--bg-gray-text);
    }
  }
  .giris-yap-btn {
    display: flex;
    width: 450px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid var(--dark-green);
    background-color: var(--dark-green);
    color: var(--soft-green);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      background-color: transparent !important;
      color: var(--dark-green) !important;
      border: 1px solid var(--dark-green) !important;
    }
  }
}
.dark-green-circle {
  background-color: var(--dark-green);
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
}
.box-content {
  border-radius: 6.425px;
  border: 0.428px solid var(--gray2);
  color: var(--black);
  text-align: right;
  font-family: Montserrat;
  font-size: 20px;
  height: auto;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.detayli-bilgi {
  .detay-img {
    max-height: 100%;
    max-width: 100%;
    height: 250px;
    width: 408px;
    object-fit: cover;
    border-radius: 20px;
  }
}
.dark-btnss {
  display: inline-flex;
  padding: 19px 78px 19px 82px !important;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  background-color: var(--dark-green) !important;
  color: var(--light-green) !important;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  &:hover {
    background-color: var(--light-green) !important;
    color: var(--white) !important;
  }
}
@media (max-width: 772px) {
  .gen-container {
    padding: 10px 10px;
  }
  .position-absolute-slider-text {
    p {
      margin: 0 !important;
      font-size: 12px !important;
      color: var(--white) !important;
    }
    .position-absolute-slider-text {
      z-index: 9093 !important;
      position: absolute;
      top: 50%;
      right: 0px !important;
      transform: translate(-50%, -50%);
      padding: 20px;
    }
    .outline-white-btn {
      display: flex;
      padding: 3px 25px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--white);
      color: var(--white);
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
    }
    .white-btn {
      display: flex;
      padding: 3px 25px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--dark-green);
      font-family: Inter;
      font-size: 15px;
      border: 1px solid var(--white);
      font-weight: 500;
      border-radius: 10px;
      background-color: var(--white);
    }
  }
  .bagis-yap {
    padding: 20px !important;
  }
  .tabs {
    margin-top: 20px !important;
    padding: 3px !important;
  }
  .not-alone .left-side {
    padding: 30px;
    margin-left: 3px;
  }
  .footer-list {
    gap: 35px !important;
  }
  .kurumsal-container {
    padding: 0px 25px !important;
  }
  .category-content {
    padding: 0px 25px !important;
  }
  .hesap-container {
    padding: 0px 25px;
  }
  .hesap-card {
    margin: 10px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1px solid rgba(34, 34, 34, 0.3);
  }
  .width-100 {
    max-width: 100%;
    width: 100% !important;
  }
  .bagis-content {
    width: 100%;
    .bagis-yap-btn {
      width: 100% !important;
    }
  }
}
input:focus {
  box-shadow: none !important;
  border-color: var(--dark-green);
}
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--dark-green) !important;
}
textarea:focus {
  box-shadow: none !important;
  border-color: var(--dark-green);
}
.detay-bilgi-img {
  max-width: 100%;
  width: 408px !important;
  max-height: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-end-start-radius: 10px;
}
.detay-btns-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px !important;
  button {
    display: flex;
    padding: 14px 21px;
    justify-content: center;
    align-items: center;
    border-end-end-radius: 10px !important;
    border-end-start-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: 2px solid var(--dark-green);
    color: var(--dark-green);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    &:active {
      background-color: var(--dark-green) !important;
      border: 2px solid transparent !important;
    }
    &:hover {
      background-color: var(--dark-green) !important;
      border: 2px solid transparent !important;
    }
  }
}
.position-relative-content {
  position: relative;
  input {
    height: 51px !important;
  }
  .absolute-content-boxs {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0px 10px 10px 0px;
    background-color: var(--light-green);
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--dark-green);
      color: var(--white);
    }
  }
}
.dark-green-btn {
  background-color: var(--dark-green) !important;
  color: var(--dark);
  font-family: Montserrat;
  font-style: 14px;
  font-weight: 600;
  border: 1px solid transparent !important;
  &:hover {
    font-style: 14px;
    font-weight: 600;
    background-color: var(--soft-green) !important ;
    color: var(--dark-green) !important;
  }
}
@media (max-width: 992px) {
  .btns-dark {
    width: 100% !important;
    .dark-btnss {
      width: 100% !important;
    }
  }
  .giris-yap-content {
    border-radius: 15px;
    flex-shrink: 0;
    padding-bottom: 20px;
    width: 1000px;
    max-width: 100%;
    .image-box {
      object-fit: cover;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      max-height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 1200px) {
  .login-register {
    padding: 10px 20px;
  }
  .giris-yap-content {
    border-radius: 15px;
    border: 1px solid transparent !important;
    .image-box {
      border-radius: 14px;
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}
.kurumsal-container {
  .accordion {
    .accordion-item {
      margin-bottom: 20px;
      border: 1px solid #dee2e6 !important;
      border-radius: 10px;
    }
    .accordion-button {
      padding: 1.5rem 1.8rem !important;
      font-size: 20px;
      font-weight: 600;
      border-radius: 10px;
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.3704 15.8351L18.8001 9.20467C19.2013 8.79094 18.9581 8 18.4297 8H5.5703C5.04189 8 4.79869 8.79094 5.1999 9.20467L11.6296 15.8351C11.8427 16.0549 12.1573 16.0549 12.3704 15.8351Z' fill='%231C274C'/%3E%3C/svg%3E");
      }
      &:not(.collapsed) {
        background-color: #2b4141;
        color: #e1fdae;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.3704 15.8351L18.8001 9.20467C19.2013 8.79094 18.9581 8 18.4297 8H5.5703C5.04189 8 4.79869 8.79094 5.1999 9.20467L11.6296 15.8351C11.8427 16.0549 12.1573 16.0549 12.3704 15.8351Z' fill='%23E1FDAE'/%3E%3C/svg%3E");
          transform: none !important;
        }
      }
    }
    .accordion-item:last-of-type
      > .accordion-header
      .accordion-button.collapsed {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
    .accordion-item:first-of-type > .accordion-header .accordion-button {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
    .accordion-body {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
  }
  .yonetim {
    margin-left: 50px;
    &:not(:last-child) {
      border-bottom: 1px solid #bbb;
      padding-bottom: 50px;
    }
    img {
      height: 450px;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.mt-48 {
  margin-top: 48px;
}
.livvic {
  font-family: "Livvic", sans-serif;
}
.border-bottoms:not(:last-child) {
  border-bottom: 1px solid rgba(34, 34, 34, 0.3);
  padding-bottom: 1.2rem;
}

.ozet {
  .ozet-user {
    display: flex;
    width: 360px;
    height: 60px;
    padding: 20px 246px 20px 25px;
    text-wrap: nowrap;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--dark-green);
    color: #222;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    .circle {
      width: 40px;
      height: 40px;
      background-color: #456262;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--light-green);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      border-radius: 50%;
    }
    .user-name {
      color: #456262;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
    }
    .user-edit {
      color: #222;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
    }
  }
  .tabs-active {
    display: flex;
    width: 360px;
    height: 60px;
    padding: 20px 246px 20px 25px;
    align-items: center;
    border-radius: 5px;
    background-color: var(--dark-green);
    color: var(--white) !important;
  }
  .tabs {
    display: flex;
    width: 360px;
    height: 60px;
    padding: 20px 246px 20px 25px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--dark-green);
    color: #222;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      display: flex;
      width: 360px;
      height: 60px;
      padding: 20px 246px 20px 25px;
      align-items: center;
      border-radius: 5px;
      background-color: var(--dark-green);
      color: var(--white);
    }
  }
}
.hesap-ozetim-container {
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #bbb;
  .bagis-card {
    border-radius: 10px;
    border: 1px solid #adadad;
    background-color: #fff;
    display: flex;
    padding: 22px 2px;
    justify-content: center;
    align-items: center;
  }
  .btn-dark-green {
    display: flex;
    width: 198px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--dark-green);
    background-color: var(--dark-green);
    color: var(--light-green);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      border: 1px solid var(--dark-green);
      background-color: var(--white);
      color: var(--dark-green);
    }
  }
}
.hesap-ayarlari-input {
  display: flex;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 50px !important;
  padding: 16.667px 22.222px 17.5px 22.222px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray-text);
  background-color: var(--white);
}
.form-control:focus {
  box-shadow: none !important;
  border-color: var(--gray-text) !important;
}

// gonullu form & zekat form

.gonullu-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.zekat-form {
  padding: 50px 80px;
  .coll {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
  }
}
.zekat-form .nav-item .nav-link{
  color: var(--green);
  font-weight: 600;
}